import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import "firebase/analytics";
import { db } from "@/main";
import sessionSearchModule from "@/store/sessionSearch.js";
import defaultSections from "@/store/defaultSections.js";
import baseConfig from "@/store/baseConfig.js";
import { formatDate, formatSecondsToDate } from "@/mixins/formatDate.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    sessionSearch: sessionSearchModule,
  },
  state: {
    meetingCode: baseConfig.MeetingCode,
    meetingName: baseConfig.MeetingName,
    sponsor: baseConfig.Sponsor,
    cmeOnly: baseConfig.CmeOnly ? baseConfig.CmeOnly : false,
    loginUsingRegCode: baseConfig.LoginUsingRegCode  ? baseConfig.LoginUsingRegCode : true,
    isCmeSingleDay: baseConfig.IsCmeSingleDay  ? baseConfig.IsCmeSingleDay : false,
    mobileSidebarOpen: false,
    sections: [],
    sessions: [],
    speakers: [],
    sessionSchedules: [],
    faqs: [],
    maps: [],
    meetings: [],
    cctfAffiliateEvents: [],
    attendeeFavorites: [],
    attendeeNotes: [],
    attendeeCMEClaims: [],
    testers: baseConfig.Testers,
    errors: {
      bindingErrors: {},
    },
    defaultSections: defaultSections,
    lastName: "",
    regCode: "",
    user: undefined,
    firestoreUser: undefined,
    loginError: undefined,
  },
  mutations: {
    ...vuexfireMutations,
    toggleSidebar(state) {
      let scrollPos =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;

      state.mobileSidebarOpen = !state.mobileSidebarOpen;
      setTimeout(function () {
        document.documentElement.scrollTop = document.body.scrollTop =
          scrollPos;
      }, 100);
    },
  },
  actions: {
    login: firestoreAction((context, payload) => {
      return new Promise((resolve, reject) => {
        // Login to AAD SYSTEM
        let attendeeLogin = {
          MeetingCode: context.state.meetingCode || "AM2022",
          RegCode: payload.regCode,
          LastName: payload.lastName,
          Email: payload.email,
        };

        fetch("https://meetings.aad.org/attendees/authenticate/user", {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(attendeeLogin),
        })
          .then((resp) => resp.json())
          // Retrieved Login Token, Login to Firestore
          .then((data) => {
            if (!data || !data.loginToken) {
              reject({
                ErrorMessage:
                  "No data returned from server or missing login token",
                ErrorSource: "Login",
              });
            }

            // There is a login token, log into firebase
            firebase
              .auth()
              .signInWithCustomToken(data.loginToken)
              .then((userCredential) => {
                // Signed in
                context.state.firestoreUser = userCredential.user;
              })
              .catch((error) => {
                // error signing in to firestore
                reject({ ErrorMessage: error.message, ErrorSource: "Login" });
              });

            return data;
          })
          .then((data) => {
            // Store user from AAD in Vuex if we got this far.
            context.state.user = data;

            // Store user from AAD in localStorage to prevent constant logins
            localStorage.user = JSON.stringify(data);
          })
          .then(() => {
            // Clear out error message
            context.state.loginError = undefined;

            // Bind favorites and notes
            context.dispatch("loadFavorites");
            context.dispatch("loadNotes");

            // Log to firebase analytics
            firebase.analytics().logEvent("login", {
              method: "login",
              user: context.state.user.id || null,
              application: "web",
            });

            // Need to resolve to the .then function
            resolve();
          })

          // Error authenticating from the AAD service
          .catch((err) => {
            // Clear logins just in case
            context.state.firestoreUser = undefined;
            context.state.user = undefined;

            localStorage.removeItem("user");

            // If there isn't a logged in user, sign in anonymously, used on restart with stored user if there is an error.
            if (!firebase.auth().currentUser) {
              firebase.auth().signInAnonymously();
            }

            // Log to firebase
            firebase.analytics().logEvent("app_caught_exception", {
              ex_message: err,
              class: "Login",
              application: "web",
            });

            reject({ ErrorMessage: err, ErrorSource: "Login" });
          });
      });
    }),
    logout: firestoreAction((context) => {
      // Remove from Local Storage
      localStorage.removeItem("user");

      // Log out of firebase
      firebase.auth().signOut();

      // unbind references
      context.unbindFirestoreRef("attendeeNotes");
      context.unbindFirestoreRef("attendeeFavorites");
      context.unbindFirestoreRef("attendeeCMEClaims");

      // Log to firebase
      firebase.analytics().logEvent("login", {
        method: "logout",
        user: context.state.user.id || null,
        application: "web",
      });

      // Clear variables in vuex
      context.state.user = undefined;
      context.state.firestoreUser = undefined;
      context.state.attendeeNotes = undefined;
      context.state.attendeeFavorites = undefined;
      context.state.attendeeCMEClaims = undefined;

      // Sign in anonymously to be able to read data
      firebase.auth().signInAnonymously();
    }),
    loadSections: firestoreAction((context) => {
      if (
        context.state.sections == undefined ||
        context.state.sections.length == 0
      ) {
        // Log sidebar load
        firebase
          .analytics()
          .logEvent("sidebar_binding", { application: "web" });

        context.bindFirestoreRef(
          "sections",
          db
            .collection("Sections")
            .where("MeetingCode", "==", context.state.meetingCode || "")
            .orderBy("SortOrder")
        );
      }
    }),

    loadSessions: firestoreAction((context) => {
      if (
        context.state.sessions == undefined ||
        context.state.sessions.length == 0
      ) {
        firebase
          .analytics()
          .logEvent("session_binding", { application: "web" });

        context.bindFirestoreRef(
          "sessions",
          db
            .collection("Sessions")
            .where("MeetingCode", "==", context.state.meetingCode || "")
        );
      }
    }),

    loadSpeakers: firestoreAction((context) => {
      if (
        context.state.speakers == undefined ||
        context.state.speakers.length == 0
      ) {
        firebase
          .analytics()
          .logEvent("speaker_binding", { application: "web" });

        context.bindFirestoreRef(
          "speakers",
          db
            .collection("Speakers")
            .where("MeetingCode", "==", context.state.meetingCode || "")
            .where("IsActive", "==", true)
            .orderBy("LastName", "asc")
        );
      }
    }),

    loadSessionSchedules: firestoreAction((context) => {
      if (
        context.state.sessionSchedules == undefined ||
        context.state.sessionSchedules.length == 0
      ) {
        firebase
          .analytics()
          .logEvent("session_schedule_binding", { application: "web" });

        context.bindFirestoreRef(
          "sessionSchedules",
          db
            .collection("SessionSchedules")
            .where("MeetingCode", "==", context.state.meetingCode || "")
            .where("IsActive", "==", true)
        );
      }
    }),

    loadFaqs: firestoreAction((context) => {
      if (context.state.faqs == undefined || context.state.faqs.length == 0) {
        firebase.analytics().logEvent("faq_binding", { application: "web" });

        context.bindFirestoreRef("faqs", db.collection("Faqs"));
      }
    }),

    loadMaps: firestoreAction((context) => {
      if (context.state.maps == undefined || context.state.maps.length == 0) {
        firebase.analytics().logEvent("map_binding", { application: "web" });

        context.bindFirestoreRef("maps", db.collection("Maps"));
      }
    }),

    loadMeetings: firestoreAction((context) => {
      if (context.state.maps == undefined || context.state.maps.length == 0) {
        firebase
          .analytics()
          .logEvent("meeting_binding", { application: "web" });

        context.bindFirestoreRef("meetings", db.collection("Meetings"));
      }

      // TODO : Check for current meeting?
    }),

    loadCCTFAffiliateEvents: firestoreAction((context) => {
      if (
        context.state.cctfAffiliateEvents == undefined ||
        context.state.cctfAffiliateEvents.length == 0
      ) {
        firebase
          .analytics()
          .logEvent("cctf_affiliates_binding", { application: "web" });

        context.bindFirestoreRef(
          "cctfAffiliateEvents",
          db
            .collection("CCTFAffiliateEvents")
            .where("MeetingCode", "==", context.state.meetingCode || "")
        );
      }
    }),

    loadFavorites: firestoreAction((context) => {
      if (
        context.state.user != undefined &&
        (context.state.attendeeFavorites == undefined ||
          context.state.attendeeFavorites.length == 0)
      ) {
        firebase
          .analytics()
          .logEvent("favorites_binding", { application: "web" });

        context.bindFirestoreRef(
          "attendeeFavorites",
          db
            .collection("AttendeeFavorites")
            .where("MeetingCode", "==", context.state.meetingCode || "")
            .where("AttendeeId", "==", context.state.user.id.toString())
        );
      }
    }),

    loadAttendeeCMEClaims: firestoreAction((context) => {
      if (
        context.state.user != undefined &&
        (context.state.attendeeCMEClaims == undefined ||
          context.state.attendeeCMEClaims.length == 0)
      ) {
        firebase
          .analytics()
          .logEvent("attendeeCMEClaims", { application: "web" });

        context.bindFirestoreRef(
          "attendeeCMEClaims",
          db
            .collection("AttendeeCMEClaims")
            .where("MeetingCode", "==", context.state.meetingCode || "")
            .where("AttendeeId", "==", context.state.user.id.toString())
        );
      }
    }),

    loadNotes: firestoreAction((context) => {
      if (
        context.state.user != undefined &&
        (context.state.attendeeNotes == undefined ||
          context.state.attendeeNotes.length == 0)
      ) {
        firebase.analytics().logEvent("notes_binding", { application: "web" });

        context.bindFirestoreRef(
          "attendeeNotes",
          db
            .collection("AttendeeNotes")
            .where("MeetingCode", "==", context.state.meetingCode || "")
            .where("AttendeeId", "==", context.state.user.id.toString())
        );
      }
    }),

    toggleFavorite: function (context, sessionId) {
      // Check if the favorite exists already and IsActive
      if (context.getters.isSessionInFavorites(sessionId)) {
        // This session exists in favorites, mark it inactive
        db.collection("AttendeeFavorites")
          .doc(context.getters.getFavoriteId(sessionId))
          .set({ IsActive: false }, { merge: true });

        // Log to firebase
        let session = context.getters.getSessionById(sessionId);
        firebase.analytics().logEvent("session_favorite", {
          method: "remove",
          session_id: sessionId,
          session_code: session.SessionCode || null,
          application: "web",
        });
      } else {
        // This session does not exist in favorites as an IsActive

        // If it exists but is inactive, mark it active
        if (context.getters.getFavoriteId(sessionId)) {
          db.collection("AttendeeFavorites")
            .doc(context.getters.getFavoriteId(sessionId))
            .set({ IsActive: true }, { merge: true });
        } else {
          // If it does not exist as inactive, make a new record for it
          db.collection("AttendeeFavorites").add({
            AttendeeId: context.state.user.id.toString(),
            IsActive: true,
            MeetingCode: context.state.meetingCode,
            SessionId: sessionId,
          });
        }

        // Log to firebase
        let session = context.getters.getSessionById(sessionId);
        firebase.analytics().logEvent("session_favorite", {
          method: "add",
          session_id: sessionId,
          session_code: session.SessionCode || null,
          application: "web",
        });
      }
    },
    saveNote: function (context, payload) {
      if (context.getters.isLoggedIn) {
        // User is logged in. Check if this note exists already
        let existingNote = context.getters.getNoteByIdAndType(payload);
        let newNoteId = 0;

        // Clean up linkId to be a number and not a string
        if(typeof payload.LinkId === 'string'){
          payload.LinkId = parseInt(payload.LinkId);
        }

        if (!existingNote || !existingNote.id) {
          // Create note
          newNoteId = db.collection("AttendeeNotes").add({
            AttendeeId: context.state.user.id.toString(),
            IsActive: true,
            MeetingCode: context.state.meetingCode,
            LastUpdate: new Date(),
            LinkId: payload.LinkId,
            NoteBody: payload.NoteBody,
            NoteType: payload.NoteType,
            Title: payload.Title,
          });
        }


        // Set Note, if there is no body make sure to mark as !IsActive
        else {
          db.collection("AttendeeNotes")
            .doc(existingNote.id)
            .set(
              {
                IsActive: payload.NoteBody != "",
                AttendeeId: context.state.user.id.toString(),
                MeetingCode: context.state.meetingCode,
                LastUpdate: new Date(),
                LinkId: payload.LinkId,
                NoteBody: payload.NoteBody,
                NoteType: payload.NoteType,
                Title: payload.Title,
              },
              { merge: true }
            )
        }
        // Log to firebase
        existingNote = context.getters.getNoteByIdAndType(payload);
        firebase.analytics().logEvent("update_note_list", {
          item_id: existingNote ? existingNote.id : newNoteId,
          item_name: payload.Title,
          attendee_id: payload.attendeeId,
          application: "web",
        });
      }
    },
    saveAttendeeCreditClaim: function (context, payload) {
      if (context.getters.isLoggedIn) {
        // User is logged in. Check if this claim exists already

        let existingAttendeeCreditClaim =
          context.getters.getCreditClaimBySessionCode(payload.SessionCode);

        if (!existingAttendeeCreditClaim) {
          // Create claim
          db.collection("AttendeeCMEClaims").add({
            Answers: JSON.stringify(payload),
            AttendeeId: context.state.user.id.toString(),
            ClaimId: payload.SurveyContentfulId,
            Complete: true,
            CreditsClaimed: payload.CreditsClaimed,
            IsActive: true,
            LastUpdatedOn: new Date(),
            MeetingCode: context.getters.getMeetingCode,
            SubmittedOn: new Date(),
            Submitted: true,
            SessionCode: payload.SessionCode,
          });
        }

        // Set claim
        else {
          db.collection("AttendeeCMEClaims")
            .doc(existingAttendeeCreditClaim.id)
            .set(
              {
                Answers: JSON.stringify(payload),
                AttendeeId: context.state.user.id.toString(),
                ClaimId: payload.SurveyContentfulId,
                Complete: true,
                CreditsClaimed: payload.CreditsClaimed,
                IsActive: true,
                LastUpdatedOn: new Date(),
                MeetingCode: context.getters.getMeetingCode,
                SessionCode: payload.SessionCode,
              },
              { merge: true }
            );
        }
      }
    },
    deleteAttendeeCMEClaim: function (context, payload) {
      if (context.getters.isLoggedIn) {
        // User is logged in. Check if this claim exists

        let existingAttendeeCreditClaim =
          context.getters.getCreditClaimBySessionCode(payload.SessionCode);

        if (!existingAttendeeCreditClaim) {
          return undefined;
        }

        // set isactive to false on claim
        else {
          db.collection("AttendeeCMEClaims")
            .doc(existingAttendeeCreditClaim.id)
            .set(
              {
                LastUpdatedOn: new Date(),
                IsActive: false,
              },
              { merge: true }
            );
        }
      }
    },
  },
  getters: {
    // Accessors that filter out by meeting code because I don't have the indexes to do a where on the tables.
    allSections(state) {
      return state.defaultSections
        .concat(state.sections)
        .filter((section) => section.DisplayMediums && section.DisplayMediums.some((medium) => {
          return medium == "Web";
        }));
    },
    getMeetingCode(state) {
      return state.meetingCode;
    },
    getMeetingName(state) {
      return state.meetingName;
    },
    getSponsor(state) {
      return state.sponsor;
    },
    homePageSections(state, getters) {
      return getters.allSections.filter((section) => section.MainDisplay);
    },
    sidebarSections(state, getters) {
      return getters.allSections.filter((section) => section.MenuDisplay);
    },
    allSessions(state) {
      return state.sessions
        .filter(
          (session) =>
            session.IsActive && session.MeetingCode == state.meetingCode
        )
        .sort((a, b) => {
          return (
            a.StartTime.seconds - b.StartTime.seconds ||
            a.SessionCode.localeCompare(b.SessionCode)
          );
        });
    },
    allSpeakers(state) {
      return state.speakers.filter(
        (speaker) =>
          speaker.IsActive && speaker.MeetingCode == state.meetingCode
      );
    },
    allFaqs(state) {
      return state.faqs.filter(
        (faq) =>
          faq.MeetingCode == state.meetingCode &&
          faq.DisplayMediums.includes("Web")
      );
    },
    allMaps(state) {
      return state.maps
        .filter(
          (singleMap) =>
            singleMap.IsActive && singleMap.MeetingCode == state.meetingCode
        )
        .map((singleMap) => {
          return {
            ...singleMap,
            id: singleMap.id,
          };
        });
    },
    allNotes(state) {
      return state.attendeeNotes;
    },
    allCCTFAffiliateEvents(state) {
      return state.cctfAffiliateEvents.sort((a, b) => {
        return a.StartDate.seconds - b.StartDate.seconds;
      });
    },
    getMapById: (state) => (id) => {
      return state.maps.find((singleMap) => singleMap.id == id);
    },
    getSessionById: (state) => (id) => {
      return state.sessions.find((session) => session.id == id);
    },
    getSessionBySessionCode: (state) => (sessionCode) => {
      return state.sessions.find(
        (session) => session.SessionCode == sessionCode
      );
    },
    getSpeakerById: (state) => (id) => {
      return state.speakers.find((speaker) => speaker.id == id);
    },
    getCreditClaimBySessionCode: (state) => (sessionCode) => {
      return state.attendeeCMEClaims.find(
        (claim) => claim.SessionCode == sessionCode
      );
    },
    isCreditClaimActiveBySessionCode: (state) => (sessionCode) => {
      return state.attendeeCMEClaims.find(
        (claim) => claim.SessionCode == sessionCode && claim.IsActive
      );
    },
    getNumberOfCreditsClaimed:
      (state, getters) =>
      (date = undefined) => {
        // If no date is passed in, return the total of all active credits
        let activeClaims = state.attendeeCMEClaims.filter(
          (claim) => claim.IsActive
        );

        // If there is a FORMATTED date passed in, show the credit claims for that date
        if (date != undefined) {
          activeClaims = state.attendeeCMEClaims.filter((claim) => {
            return (
              claim.IsActive &&
              getters.getSessionBySessionCode(claim.SessionCode) != undefined &&
              formatSecondsToDate(
                getters.getSessionBySessionCode(claim.SessionCode).StartTime
                  .seconds
              ) == date
            );
          });
        }

        let sum = 0;

        activeClaims.forEach((claim) => (sum += claim.CreditsClaimed));

        return sum;
      },
    checkSessionCreditClaimStatus: (state, getters) => (payload) => {
      // Assume valid response, return with error if not valid
      let response = {
        valid: true,
      };

      // Get the session in question
      let session = getters.getSessionById(payload.sessionId);

      // If this is not an edit, make sure that more credits can be claimed.
      if (payload.operation != "Edit") {
        if (getters.checkNumberOfCreditsAvailableOnDay(session) <= 0) {
          response.valid = false;
          response.message = "You cannot claim any more credits for this date.";
        }
      }

      // Make sure session has started
      if (session && new Date(session.StartTime.seconds * 1000) > new Date()) {
        response.valid = false;
        response.message = "This session has not started yet.";
      }

      // Check if the user has been verified
      if (!state.user.isVerified) {
        response.valid = false;
        response.message =
          "You must be verified at a booth in order to claim CME Credits on the web.";
      }

      /*
      Removed for testing purposes.
      if(session && !getters.isMeetingStarted){
        response.valid = false;
        response.message = "The meeting has not started yet.";
      }
      */

      return response;
    },
    checkNumberOfCreditsAvailableOnDay: (state, getters) => (session) => {
      let sessionDateString = formatSecondsToDate(session.StartTime.seconds);
      if (sessionDateString) {
        let creditsClaimed =
          getters.getNumberOfCreditsClaimed(sessionDateString);
        let creditLimit = getters.getCreditLimitByDay(sessionDateString);

        return creditLimit - creditsClaimed;
      }

      return 0;
    },
    getCreditLimitByDay: (state, getters) => (date) => {
      // Loop through limits
      // Convert into a date string and compare
      let dailyLimit = 0;

      getters.currentMeeting.CmeDailyLimits.forEach((limit) => {
        if (formatDate(new Date(limit.Day)) == date) {
          dailyLimit = limit.CreditAmount;
        }
      });

      return dailyLimit;
    },
    sessionSchedulesBySessionId: (state) => (id) => {
      return state.sessionSchedules
        .filter((schedule) => schedule.SessionID == id)
        .sort((a, b) => a.StartTime.seconds - b.StartTime.seconds);
    },
    isSessionInFavorites: (state) => (sessionId) => {
      // If there are no favorites, return false
      if (!state.attendeeFavorites) {
        return false;
      }

      // If there are favorites, search them
      return state.attendeeFavorites.some(
        (favorite) => favorite.SessionId == sessionId && favorite.IsActive
      );
    },
    getNoteByIdAndType: (state) => (payload) => {
      // If there are no notes, return false
      if (!state.attendeeNotes) {
        return false;
      }

      // If there are notes, search them by type
      return state.attendeeNotes.find(
        (note) =>
          note.LinkId == payload.LinkId && note.NoteType == payload.NoteType
      );
    },
    anyActiveFavorites(state) {
      // Check if there are any favorites at all that are active, used to hide favorites filter.
      return (
        state.attendeeFavorites &&
        state.attendeeFavorites.some((favorite) => favorite.IsActive)
      );
    },
    isLoggedIn(state) {
      return state.user ? true : false;
    },
    isLoggedInUserVerified(state) {
      if (!state.user) {
        // If nobody is logged in, they can't be verified
        return false;
      } else {
        if (state.user.isVerified) {
          return true;
        } else {
          return false;
        }
      }
    },
    loggedInUser(state) {
      return state.user;
    },
    getFavoriteId: (state) => (sessionId) => {
      // Used for the deletion of a favorite. Make sure to check one exists first.
      let favorite = state.attendeeFavorites.find(
        (favorite) => favorite.SessionId == sessionId
      );

      if (favorite) {
        return favorite.id;
      } else {
        return undefined;
      }
    },
    currentMeeting: (state) => {
      let meeting = state.meetings.find(
        (meeting) => meeting.id == state.meetingCode
      );
      return meeting;
    },
    isMeetingRunning: (state, getters) => {
      // Check if the meeting is currently running
      let meeting = getters.currentMeeting;

      let meetingStart = new Date(meeting.StartDate.seconds * 1000);
      let meetingEnd = new Date(meeting.EndDate.seconds * 1000);
      let currentDateTime = new Date();

      return meetingStart < currentDateTime && currentDateTime < meetingEnd;
    },
    isMeetingStarted: (state, getters) => {
      // Check if the meeting is currently running
      let meeting = getters.currentMeeting;

      let meetingStart = new Date(meeting.StartDate.seconds * 1000);
      let currentDateTime = new Date();

      return meetingStart < currentDateTime;
    },
    canViewTestSessions: (state, getters) => {
      // Used to control is test sessions show up for the user in the session listing.
      // Just an extra layer to neaten up the display for non-testers.
      // Update the list of testers in the baseconfig.js file.
      if (!getters.loggedInUser) {
        return false;
      }

      return state.testers.some(
        (x) => x == getters.loggedInUser.registrationCode
      );
    },
  },
});

export default store;
